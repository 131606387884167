import React from "react";
import { FaBook, FaHome } from "react-icons/fa";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

const Resource = () => {
  const version = "1.0.0";
  return (
    <>
      <Navbar />
      <div className="resource">
        <section className="content-header">
          <div>
            <h1>Subjects Module</h1>
          </div>
          <nav className="breadcrumb">
            <ol>
              <li>
                <a href="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </a>
              </li>
              <li className="active">Subjects</li>
            </ol>
          </nav>
        </section>
        <div className="section">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="card_header">
            <FaBook className="card-icon pri" />
            <h2>Primary</h2>
            <p>Number of Subjects: 2</p>
            <p>
              <Link to={"/viewPriTopics"}>View Details »</Link>
            </p>
          </div>
          <div className="card_header">
            <FaBook className="card-icon sec" />
            <h2>Secondary</h2>
            <p>Number of Subjects: 4</p>
            <p>
              <Link to={"/viewSecTopics"}>View Details »</Link>
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Footer version={version} />
    </>
  );
};

export default Resource;
