import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import parse from "html-react-parser";

export default function ActivityDetails() {
  const [activityDetails, setActivityDetails] = useState([]);
  const [session, setSession] = useState([]);
  const { activityId, sessionName, sessionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivityDetails = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getActivity/${activityId}`
        );
        setActivityDetails(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching activites:", error);
        console.error("Error fetching video:", error.message); // Log error message
      }
    };
    fetchActivityDetails();
  }, [activityId]);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSession/${sessionId}`
        );
        setSession(response.data);
        console.log("Session got", response.data);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, [sessionId]);

  const sessionItem = session.find((item) => item.id === activityDetails.id);

  const handleBack = () => {
    navigate(`/activity-list/${activityDetails.session}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <div className="activity-head">
          <p>
            <b>Activity details...</b>
            {activityDetails.title}
          </p>
          <button className="backward" onClick={handleBack}>
            Back
          </button>
        </div>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <br></br>
        <div className="activitydetails">
          <div className="col-md-3">
            <SideBar />
          </div>

          {activityDetails.mediaType === "text" && (
            <div className="activity-container">
              {/* Render text details */}
              <Card key={activityDetails.id} className="texts">
                <div className="text">
                  {/* <Card.Title>
                    <strong>Activity Name: </strong>
                    {parse(activityDetails.title)}
                    {""}
                    <br />
                  </Card.Title> */}
                  <Card.Text>
                    <strong>Duration: </strong>
                    {parseInt(activityDetails.time)} minutes {""}
                    <br />
                  </Card.Text>
                  <Card.Text>
                    <strong>Teachers Activity: </strong>
                    {parse(activityDetails.teacherActivity)}
                    {""}
                    {/* <br /> */}
                  </Card.Text>
                  <Card.Text>
                    <strong>Learners Activity: </strong>
                    {parse(activityDetails.studentActivity)}
                    {""}
                    {/* <br /> */}
                  </Card.Text>
                  <Card.Text>
                    <strong>Activity Note: </strong>
                    {parse(activityDetails.notes)}
                    {""}
                    <br />
                  </Card.Text>
                </div>
              </Card>
            </div>
          )}

          {activityDetails.mediaType === "image" && (
            <div className="activity-container">
              {/* Render image details */}
              <Card key={activityDetails.id} className="texts">
                <Card.Body>
                  {/* <Card.Title>
                    <strong>Activity Title: </strong>
                    {activityDetails.title}
                  </Card.Title> */}
                  {/* <Card.Text>{activityDetails.time}-mins</Card.Text> */}
                  <Card.Text>
                    <strong>File Name: </strong>
                    {activityDetails.image_title}
                  </Card.Text>

                  {activityDetails && (
                    <img
                      src={`http://161.97.81.168:8080${activityDetails.image}`}
                      alt="Activity file_picture"
                      style={{
                        width: "700px",
                        marginTop: "auto",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </div>
          )}

          {activityDetails.mediaType === "video" && (
            <div className="activity-container">
              {/* Render video details */}
              <Card key={activityDetails.id} className="texts">
                <Card.Body>
                  <div className="text">
                    {/* <Card.Title>
                    <strong>Activity Title: </strong>
                    {activityDetails.title}
                  </Card.Title> */}
                    {/* <Card.Text>{activityDetails.time}-mins</Card.Text> */}
                    {/* <Card.Text>{activityDetails.teacherActivity}</Card.Text>
                  <Card.Text>{activityDetails.studentActivity}</Card.Text> */}
                    <Card.Text>
                      <strong>File Name: </strong>
                      {activityDetails.video_title}
                    </Card.Text>
                    {activityDetails && (
                      <video
                        controls
                        className="img-fluid"
                        style={{
                          width: "700px",
                          borderRadius: "4px",
                          marginTop: "auto",
                        }}
                      >
                        <source
                          src={`http://161.97.81.168:8080${activityDetails.video}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
      <Footer version={version} />
    </>
  );
}
