import React, { useState, useEffect } from "react";
import {
  FaCog,
  FaBook,
  FaTable,
  FaCalendarAlt,
  FaHome,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import Navbar from "../Components/Navbar";
import SideBar from "./SideBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";

export const Dashboard = () => {
  const [topic, setTopic] = useState([]);
  const [theme, setTheme] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get("http://161.97.81.168:8080/");
        setTopic(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchTopics();
  }, []);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.get(
          "http://161.97.81.168:8080/viewTheme/"
        );
        setTheme(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchThemes();
  }, []);

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="home">
        <section className="content-header">
          <h1>Dashboard</h1>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Dashboard</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <br />
        <div className="containers">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="row">
                <div className="huge" style={{ fontSize: "48px" }}>
                  {topic.length}
                </div>
                <div>Total Topics</div>

                <div className="icon text-right">
                  <span className="pull-right">
                    <FaCog className="custom-icon-right" size={30} />
                  </span>
                </div>
              </div>
            </div>
            <Link to="/viewTopics">
              <div className="panel-footer">
                <span className="pull-left">View Details</span>{" "}
                <span className="pull-right">
                  <FaArrowAltCircleRight
                    className="panel-icon"
                    size={15}
                    color="#f8f8f8"
                  />
                </span>
                <div className="clearfix"></div>
              </div>
            </Link>
          </div>
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="row">
                <div className="huge" style={{ fontSize: "48px" }}>
                  {theme.length}
                </div>
                <div>DEAR Day Themes</div>
                <div className="icon text-right">
                  <span className="pull-right">
                    <FaBook className="custom-icon-right" size={30} />
                  </span>
                </div>
              </div>
            </div>
            <Link to="/ThemesList">
              <div className="panel-footer">
                <span className="pull-left">View Details</span>{" "}
                <span className="pull-right">
                  <FaArrowAltCircleRight
                    className="panel-icon"
                    size={15}
                    color="#f8f8f8"
                  />
                </span>
                <div className="clearfix"></div>
              </div>
            </Link>
          </div>
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="row">
                <div className="huge" style={{ fontSize: "48px" }}>
                  17
                </div>
                <div>Time Table</div>
                <div className="icon text-right">
                  <span className="pull-right">
                    <FaTable className="custom-icon-right" size={30} />
                  </span>
                </div>
              </div>
            </div>
            <Link to="#">
              <div className="panel-footer">
                <span className="pull-left">View Details</span>{" "}
                <span className="pull-right">
                  <FaArrowAltCircleRight
                    className="panel-icon"
                    size={15}
                    color="#f8f8f8"
                  />
                </span>
                <div className="clearfix"></div>
              </div>
            </Link>
          </div>
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="row">
                <div className="huge" style={{ fontSize: "48px" }}>
                  II
                </div>
                <div>Current Term</div>
                <div className="icon text-right">
                  <span className="pull-right">
                    <FaCalendarAlt className="custom-icon-right" size={30} />
                  </span>
                </div>
              </div>
            </div>
            <Link to="#">
              <div className="panel-footer">
                <span className="pull-left">Change Term</span>{" "}
                <span className="pull-right">
                  <FaArrowAltCircleRight
                    className="panel-icon"
                    size={15}
                    color="#f8f8f8"
                  />
                </span>
                <div className="clearfix"></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default Dashboard;
