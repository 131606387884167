import React, { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";

const SubjectCard = () => {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    // Fetch subject data from the API
    fetch("http://localhost:5000/api/subjects")
      .then((response) => response.json())
      .then((data) => setSubjects(data))
      .catch((error) => console.error("Error fetching subjects:", error));
  }, []);

  if (subjects.length === 0) {
    return <div>Loading...</div>;
  }

  const lastTwoSubjects = subjects.slice(-2);

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="logout">
        <section className="content-header">
          <h3>Primary Section</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <FaHome className="breadcrumb-icon" />
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/sections">Subjects</a>
              </li>
              <li className="active">Secondary</li>
            </ol>
          </nav>
        </section>
        <div className="card-container">
          <div className="col-md-3">
            <SideBar />
          </div>
          {subjects.slice(0, -2).map((subject, index) => (
            <a
              key={index}
              href={`/topics/view/${subject.subjectID}`}
              className={`subject-card subject-card-${subject.subjectID}`}
            >
              {" "}
              {/* Wrap each subject card in an anchor tag */}
              <div>
                <h4>{subject.subjectName}</h4>
              </div>
            </a>
          ))}
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default SubjectCard;
