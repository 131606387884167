import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { notificationId } = useParams();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getNotification/${notificationId}`
        );
        setNotifications(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading notifications: {error.message}</div>;
  }

  return (
    <div className="notification-list">
      {notifications.length === 0 ? (
        <div className="notification-empty">No notifications</div>
      ) : (
        notifications.map((notification) => (
          <div key={notification.id} className="notification-item">
            <h4>{notification.title}</h4>
            <p>{notification.message}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default Notification;
