import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddSubTheme = () => {
  const [title, setTitle] = useState("");
  const [learning_outcome, setLearning_Outcome] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [duration, setDuration] = useState("");
  const [theme, setThemes] = useState([]);
  const { theme_id } = useParams();
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
      ["link", "image", "video"],
    ],
  };

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getTheme/${theme_id}`
        );
        setThemes(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchTheme();
  }, [theme_id]);

  function submitSession(e) {
    e.preventDefault();
    axios
      .post("http://161.97.81.168:8080/addSubTheme/", {
        title: title,
        theme: selectedTheme,
        duration: duration,
        learning_outcome: learning_outcome,
      })
      .then(function (res) {
        console.log("Response", res);
        alert("SubTheme Added successfully");

        navigate(`/viewSubTheme/${theme_id}`);
      });
  }

  const handleBack = () => {
    navigate(`/viewSubTheme/${theme_id}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Add Sub-Theme...</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              <li>
                <Link to="#">{theme.title}</Link>
              </li>
              <li className="active">Add Sub-Theme</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={`/viewSubTheme/${theme_id}`}>
            <FaListUl className="icon" />
            View Sub-Theme
          </Link>{" "}
          |
          <Link to={`/addSubTheme/${theme_id}`}>
            <FaPlus className="icon" />
            Add Sub-Theme
          </Link>
        </div>
        <br></br>
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="form-container">
          <form className="form-submit" onSubmit={submitSession}>
            <div>
              <label className="form-label" htmlFor="sessionName">
                Sub-Theme Title:
              </label>
              <input
                className="form-input"
                type="text"
                id="sessionName"
                placeholder="Enter Sub-Theme name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <Form.Group className="mb-3" controlId="formBasicSubject">
              <Form.Label>Theme Name:</Form.Label>
              <Form.Select
                value={selectedTheme}
                onChange={(e) => setSelectedTheme(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select a Theme
                </option>
                <option key={theme.id} value={theme.id}>
                  {theme.title}
                </option>
              </Form.Select>
            </Form.Group>
            <div>
              <label className="form-label" htmlFor="duration">
                Duration:
              </label>
              <select
                id="duration"
                name="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
              >
                <option value="" disabled>
                  Choose No. of Weeks
                </option>

                <option value="5">5 Weeks</option>
                <option value="6">6 Weeks</option>
                <option value="7">7 Weeks</option>
                <option value="8">8 Weeks</option>
                <option value="9">9 Weeks</option>
                <option value="10">10 Weeks</option>
              </select>
            </div>
            <hr />
            <h3>The Learning Outcomes:</h3>
            <div>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  id="resource"
                  placeholder="Enter the learning outcome here..."
                  value={learning_outcome}
                  onChange={(value) => setLearning_Outcome(value)}
                  modules={modules}
                  required
                />
              </div>
            </div>
            <div className="submit-container">
              <button type="submit" className="edittopic">
                Submit
              </button>
              <button className="back" onClick={handleBack}>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddSubTheme;
