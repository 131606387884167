import { useEffect, useState } from "react";
import { FaPlus, FaListUl, FaHome, FaTrash, FaEdit } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import { getToken } from "../Components/token";

const maxLength = 10;

export default function ActivityList() {
  const [activities, setActivities] = useState([]);
  const [sessionName, setSessionName] = useState("");
  const [topicName, setTopicName] = useState("");
  const [data, setData] = useState([]);
  const { sessionId, topicId } = useParams();
  const [session, setSession] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchActivities = async () => {
    try {
      const response = await axios.get(
        `http://161.97.81.168:8080/viewActivities/${sessionId}`
      );
      setActivities(response.data);
      console.log("activities", response.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching activities
    }
  };

  useEffect(() => {
    const getSessionName = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSession/${sessionId}`
        );
        setSessionName(response.data.sessionName);
        console.log("session name", response.data.sessionName);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };

    getSessionName();
    fetchActivities();
  }, [sessionId]);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSession/${sessionId}`
        );
        setSession(response.data);
        console.log("Session got");
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, [sessionId]);

  const topicItem = data.find((item) => item.id === session.topic);

  useEffect(() => {
    const fetchTopics = async () => {
      const token = getToken();
      console.log("initiated:", token);
      try {
        const response = await axios.get("http://161.97.81.168:8080/");
        const topicData = response.data;
        setData(topicData);
        setTopicName(topicData.topicName);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTopics();
  }, []);

  const handleDelete = async (id) => {
    setIdToDelete(id);
    setShowConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      // Perform the delete operation
      await axios.delete(
        `http://161.97.81.168:8080/deleteActivity/${idToDelete}`
      );
      // After successful deletion, hide the confirmation dialog and reload the data
      setShowConfirmation(false);
      console.log("Item Deleted Successfully!");
      setIdToDelete(null); // Reset the ID to delete
      fetchActivities(); // Re-fetch activities to update the UI
    } catch (err) {
      console.log(err);
    }
  };

  const cancelDelete = () => {
    // If user cancels, hide the confirmation dialog and reset the ID to delete
    setShowConfirmation(false);
    setIdToDelete(null);
  };

  const handleUpdateActivity = (sessionId) => {
    navigate(`/editSecActivity/${sessionId}`);
    console.log("Add session for topic with code:", sessionId);
  };

  const handleBack = () => {
    navigate(`/viewSecSessions/${session.topic}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>
            {sessionName.length > 61
              ? sessionName.substring(0, 61) + "..."
              : sessionName}
          </h3>

          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              {topicItem && (
                <li>
                  <Link to="/viewSecTopics">{topicItem.topicName}</Link>
                </li>
              )}
              <li>
                <Link to={`/viewSecSessions/${session.topic}`}>
                  {sessionName.substring(0, maxLength)}
                </Link>
              </li>
              <li className="active">Activities</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={`/viewSecSessions/${session.topic}`}>
            <FaListUl className="icon" />
            View Sessions
          </Link>{" "}
          |
          <Link to={`/add-activitydetails/${sessionId}`}>
            <FaPlus className="icon" />
            Add Activity
          </Link>
        </div>
        <br></br>
        <div className="activity-tab"></div>
        <div className="col-md-3">
          <SideBar />
        </div>
        {loading ? ( // Check loading state
          <p>
            <b>Loading...</b>
          </p>
        ) : (
          <>
            {activities.length === 0 ? ( // Check if activities are empty
              <div className="no-content-message">
                <p>
                  No <i>content found for this page!</i>
                </p>
                <br></br>
                <p>
                  Please click on the <b>+</b>Add button to add content...
                </p>
              </div>
            ) : (
              <table className="table-container">
                <thead>
                  <tr>
                    <th>Activity Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {activities.map((activity) => (
                    <tr key={activity.id}>
                      <td>
                        <Link to={`/activitydetails/${activity.id}`}>
                          {activity.title}
                        </Link>
                      </td>
                      <td className="operations">
                        <button
                          className="update_activity"
                          onClick={() => handleUpdateActivity(activity.id)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="deleteactivity"
                          onClick={() => handleDelete(activity.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}

        <div className="submit_container">
          <button className="back" onClick={handleBack}>
            Back
          </button>
        </div>
        {/* Confirmation dialog */}
        {showConfirmation && (
          <>
            <div className="overlay"></div>
            <div className="confirmation-dialog">
              <p className="confirmation-message">
                Are you sure you want to delete?
              </p>
              <div className="confirmation-buttons">
                <button className="confirm-button" onClick={confirmDelete}>
                  Yes
                </button>
                <button className="cancel-button" onClick={cancelDelete}>
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer version={version} />
    </>
  );
}
