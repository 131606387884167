import React from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const client = axios.create({
  baseURL: "http://161.97.81.168:8080/",
});

export default function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [subject, setSubject] = useState("");
  const navigate = useNavigate();

  function submitRegistration(e) {
    e.preventDefault();
    client
      .post("register/", {
        username: username,
        password: password,
        email: email,
        school: school,
        subject: subject,
      })
      .then(function (res) {
        navigate("/login");
      })
      .catch(function (error) {
        //
        alert(error);
      });
  }

  return (
    <div className="register-container">
      <h3 style={{ textAlign: "center" }}>Register for VirtualFundi here!</h3>
      <form onSubmit={submitRegistration}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>School:</label>
          <input
            type="text"
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            // required
          />
        </div>
        <div>
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        {/* <div>
          <label htmlFor="role">Role:</label>
          <select
            id="role"
            name="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Role
            </option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
        </div> */}
        {/* <div>
          <label>Class:</label>
          <input
            type="text"
            value={classRoom}
            onChange={(e) => setClassRoom(e.target.value)}
            required
          />
        </div> */}
        <button className="register" type="submit">
          Register
        </button>
      </form>
    </div>
  );
}
