import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import axios from "axios";

const AddTeacher = () => {
  const [schoolName, setSchoolName] = useState("");
  const [teachersName, setTeachersName] = useState("");
  const [classTaught, setClassTaught] = useState("");
  const [phoneName, setPhoneName] = useState("");
  const navigate = useNavigate();
  // const [qualification, setQualification] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post("http://161.97.81.168:8080/addTeacher/", {
          schoolName: schoolName,
          teachersName: teachersName,
          classTaught: classTaught,
          phoneName: phoneName,
        })
        .then(function (res) {
          console.log("Response", res);

          navigate("/viewTeachers");
        });
    } catch (error) {
      console.error("Error inserting data:", error);
      // setMsg(error);
    }
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="teachers">
        <section className="content-header">
          <h3>Add Teachers</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Add Teacher</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to="/viewTeachers">
            <FaListUl className="icon" />
            View Teachers
          </Link>
          |
          <Link to="/addTeacher">
            <FaPlus className="icon" />
            Add Teacher
          </Link>
        </div>
        <br></br>
        <div className="form-container">
          <div className="col-md-3">
            <SideBar />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="add_teachers">
              <div class="pull-left" style={{ width: "48%" }}>
                <div>
                  <label htmlFor="firstName">School Name:</label>
                  <input
                    type="text"
                    id="schoolName"
                    value={schoolName}
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="teachersName">Teacher's Name:</label>
                  <input
                    type="text"
                    id="teachersName"
                    value={teachersName}
                    onChange={(e) => setTeachersName(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="classTaught">Class Taught:</label>
                  <select
                    id="classTaught"
                    value={classTaught}
                    onChange={(e) => setClassTaught(e.target.value)}
                    required
                  >
                    <option value="" selected disabled>
                      Select Class
                    </option>
                    <option value="P.4">P.4</option>
                    <option value="P.5">P.5</option>
                    <option value="P.6">P.6</option>
                    <option value="P.7">P.7</option>
                    <option value="S.1">S.1</option>
                    <option value="S.2">S.2</option>
                    <option value="S.3">S.3</option>
                    <option value="S.4">S.4</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="phoneName">Phone Number:</label>
                  <input
                    type="text"
                    id="phoneName"
                    value={phoneName}
                    onChange={(e) => setPhoneName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="edittopics">
              <button type="submit" className="edittopic">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddTeacher;
