import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import { setToken, getToken } from "../Components/token";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddSessionPri = () => {
  const { topicId } = useParams();
  const [sessionName, setSessionName] = useState("");
  const [schoolResources, setSchoolResources] = useState("");
  const [fundibotsResources, setFundibotsResources] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [objectives, setObjectives] = useState("");
  const [duration, setDuration] = useState("");
  const [topic, setTopic] = useState([]);
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
      ["link", "image", "video"],
    ],
  };

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getTopic/${topicId}`
        );
        setTopic(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, [topicId]);

  function submitSession(e) {
    e.preventDefault();
    axios
      .post("http://161.97.81.168:8080/addSession/", {
        sessionName: sessionName,
        topic: selectedTopic,
        duration: duration,
        learningObjective: objectives,
        fundibotsResources: fundibotsResources,
        schoolResources: schoolResources,
      })
      .then(function (res) {
        console.log("Response", res);
        alert("Added successfully");

        navigate(`/viewPriSessions/${topicId}`);
      });
  }

  const handleBack = () => {
    navigate(`/viewPriSessions/${topicId}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Add Session...{topic.topicName}</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              <li>
                <Link to="/viewPriTopics">{topic.topicName}</Link>
              </li>
              <li className="active">Add_Session</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={`/viewPriSessions/${topicId}`}>
            <FaListUl className="icon" />
            View Sessions
          </Link>{" "}
          |
          <Link to={`/addSession/${topic.id}`}>
            <FaPlus className="icon" />
            Add Session
          </Link>
        </div>
        <br></br>
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="form-container">
          <form className="form-submit" onSubmit={submitSession}>
            <div>
              <label className="form-label" htmlFor="sessionName">
                Session Name:
              </label>
              <input
                className="form-input"
                type="text"
                id="sessionName"
                placeholder="Enter session name"
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                required
              />
            </div>
            <Form.Group className="mb-3" controlId="formBasicSubject">
              <Form.Label>Topic Name:</Form.Label>
              <Form.Select
                value={selectedTopic}
                onChange={(e) => setSelectedTopic(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select a Topic
                </option>
                <option key={topic.id} value={topic.id}>
                  {topic.topicName}
                </option>
              </Form.Select>
            </Form.Group>
            <hr></hr>
            <h3>Practical Resources:</h3>
            <div>
              <label className="form-label" htmlFor="resources">
                Provided by Fundi Bots:
              </label>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  id="resource"
                  placeholder="Resources to be provided by Fundi Bots"
                  value={fundibotsResources}
                  onChange={(value) => setFundibotsResources(value)}
                  modules={modules}
                  required
                />
              </div>
            </div>
            <div>
              <label className="form-label" htmlFor="resources">
                Provided by the School:
              </label>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  id="schoolresources"
                  placeholder="Reources to be provided by the school"
                  value={schoolResources}
                  onChange={(value) => setSchoolResources(value)}
                  modules={modules}
                  required
                />
              </div>
            </div>
            <h4>NB: Ensure that the kits contain all their components.</h4>
            <hr></hr>
            <h3>Session Plan:</h3>
            <div>
              <label className="form-label" htmlFor="duration">
                Duration:
              </label>
              <select
                id="duration"
                name="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Choose Duration
                </option>
                <option value="40">40 mins</option>
                <option value="60">60 mins</option>
                <option value="80">80 mins</option>
              </select>
            </div>
            <div>
              <label className="form-label" htmlFor="objectives">
                Learning Objectives:
              </label>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  value={objectives}
                  onChange={(value) => setObjectives(value)}
                  modules={modules}
                  placeholder="Objectives of the session..."
                  required
                />
              </div>
            </div>
            <div className="submit-container">
              <button type="submit" className="edittopic">
                Submit
              </button>
              <button className="back" onClick={handleBack}>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddSessionPri;

export const EditSessionPri = () => {
  const { sessionId, topicId } = useParams();
  const [sessionName, setSessionName] = useState("");
  const [schoolResources, setSchoolResources] = useState("");
  const [fundibotsResources, setFundibotsResources] = useState("");
  const [objectives, setObjectives] = useState("");
  const [duration, setDuration] = useState("");
  const [topic, setTopic] = useState([]);
  const [session, setSession] = useState([]);
  const [topicName, setTopicName] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
      ["link", "image", "video"],
    ],
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSession/${sessionId}`
        );
        const sessionData = response.data;
        setSession(sessionData);
        setSessionName(sessionData.sessionName);
        setFundibotsResources(sessionData.fundibotsResources);
        setSchoolResources(sessionData.schoolResources);
        setObjectives(sessionData.learningObjective);
        setDuration(sessionData.duration);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, [sessionId]);

  const topicItem = data.find((item) => item.id === session.topic);

  useEffect(() => {
    const fetchTopics = async () => {
      const token = getToken();
      console.log("initiated:", token);
      try {
        const response = await axios.get("http://161.97.81.168:8080/");
        const topicData = response.data;
        setData(topicData);
        setTopicName(topicData.topicName);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTopics();
  }, []);

  const updateSession = async (e) => {
    e.preventDefault();
    try {
      await axios
        .put(`http://161.97.81.168:8080/updateSession/${sessionId}`, {
          sessionName: sessionName,
          topic: session.topic,
          duration: duration,
          learningObjective: objectives,
          fundibotsResources: fundibotsResources,
          schoolResources: schoolResources,
        })
        .then((res) => {
          console.log("Response:", res);
          if (res.request.status === 200) {
            alert("Session Updated Successfully!");
            navigate(`/viewPriSessions/${session.topic}`);
          } else {
            console.log("Error");
          }
        });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleBack = () => {
    navigate(`/viewPriSessions/${session.topic}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Edit...{session.sessionName}</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              {topicItem && (
                <li>
                  <Link to="/viewPriTopics">{topicItem.topicName}</Link>
                </li>
              )}
              <li className="active">Edit Session</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={`/viewPriSessions/${session.topic}`}>
            <FaListUl className="icon" />
            View Sessions
          </Link>{" "}
          |
          <Link to={`/addSession/${topicId}`}>
            <FaPlus className="icon" />
            Add Session
          </Link>
        </div>
        <br></br>
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="form-container">
          <form className="form-submit" onSubmit={updateSession}>
            <div>
              <label className="form-label" htmlFor="sessionName">
                Session Name:
              </label>
              <input
                className="form-input"
                type="text"
                id="sessionName"
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                required
              />
            </div>
            <hr />
            <h3>Practical Resources:</h3>
            <div>
              <label className="form-label" htmlFor="resources">
                Provided by Fundi Bots:
              </label>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  id="resource"
                  value={fundibotsResources}
                  onChange={(value) => setFundibotsResources(value)}
                  modules={modules}
                  required
                />
              </div>
            </div>
            <div>
              <label className="form-label" htmlFor="resources">
                Provided by the School:
              </label>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  id="schoolresources"
                  value={schoolResources}
                  onChange={(value) => setSchoolResources(value)}
                  modules={modules}
                  required
                />
              </div>
            </div>
            <h4>NB: Ensure that the kits contain all their components.</h4>
            <hr></hr>
            <h3>Session Plan:</h3>
            <div>
              <label className="form-label" htmlFor="duration">
                Duration:
              </label>
              <select
                id="duration"
                name="duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  {session.duration}
                </option>
                <option value="40">40 mins</option>
                <option value="60">60 mins</option>
                <option value="80">80 mins</option>
              </select>
            </div>
            <div>
              <label className="form-label" htmlFor="objectives">
                Learning Objectives:
              </label>
              <div className="wrapper">
                <ReactQuill
                  className="ql-editor custom-editor"
                  style={{
                    height: "200px",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  theme="snow"
                  id="objectives"
                  value={objectives}
                  onChange={(value) => setObjectives(value)}
                  modules={modules}
                  required
                />
              </div>
            </div>
            <div className="submit-container">
              <button type="submit" className="edittopic">
                Update
              </button>
              <button className="back" onClick={handleBack}>
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};
