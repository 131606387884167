// token.js
let token = "";

export const setToken = (newToken) => {
  token = newToken;
};

export const getToken = () => {
  return token;
};
