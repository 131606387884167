import React, { useState, useEffect } from "react";

const Footer = ({ version }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <footer className={`main-footer ${visible ? "" : "hide-footer"}`}>
      <div className="pull-left hidden-xs">
        <b>Version</b> {version}
      </div>
      <strong>&copy; {new Date().getFullYear()} Fundi Bots reserved.</strong>
    </footer>
  );
};

export default Footer;
