import React from "react";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import Footer from "./Footer";

const Settings = () => {
  const version = "1.0.0";
  return (
    <>
      <Navbar />
      <div className="home">
        <h1>Settings</h1>
        <div className="cards-container">
          <div className="col-md-3">
            <SideBar />
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default Settings;
