import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import { useNavigate, useParams, Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";

const SessionListPri = () => {
  const navigate = useNavigate();

  const [sessions, setSessions] = useState([]);
  const { topicId } = useParams();
  const [topicName, setTopicName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/viewSessions/${topicId}`
        );
        console.log(response.data); // Log the response data
        setSessions(response.data);
      } catch (error) {
        console.error("Error fetching sessions:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching activities
      }
    };

    const getTopicName = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getTopic/${topicId}`
        );
        setTopicName(response.data.topicName);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    getTopicName();
    fetchSessions();
  }, [topicId]);

  const handleActivityLists = (sessionId) => {
    navigate(`/activityList/${sessionId}`);
  };

  const handleEditSession = (sessionId) => {
    navigate(`/editSecSession/${sessionId}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Sessions for topic - {topicName}</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/viewSecTopics">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              <li>
                <Link to="/viewSecTopics">{topicName}</Link>
              </li>
              <li className="active">Sessions</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to="/viewSecTopics">
            <FaListUl className="icon" />
            View Topics
          </Link>{" "}
          |
          <Link to={`/addSessions/${topicId}`}>
            <FaPlus className="icon" />
            Add Session
          </Link>
        </div>
        <br></br>
        <div className="session-tab">
          <div className="col-md-3">
            <SideBar />
          </div>
          {loading ? ( // Check loading state
            <p>
              <b>Loading...</b>
            </p>
          ) : (
            <>
              {sessions.length === 0 ? ( // Check if activities are empty
                <div className="no-content-message">
                  <p>
                    No <i>content found for this page!</i>
                  </p>
                  <br></br>
                  <p>
                    Please click on the <b>+</b>Add button to add a Session...
                  </p>
                </div>
              ) : (
                <table className="table-container">
                  <thead>
                    <tr>
                      <th>Session Name</th>
                      <th className="action">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sessions.map((session) => (
                      <tr key={session.id}>
                        <td>
                          <Link to={`/activityList/${session.id}`}>
                            {session.sessionName}
                          </Link>
                        </td>
                        <td className="action-column">
                          <button
                            className="edit"
                            onClick={() => handleEditSession(session.id)}
                          >
                            Edit
                          </button>
                          <button
                            className="session"
                            onClick={() => handleActivityLists(session.id)}
                          >
                            Activities
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      </div>

      {/* </div> */}
      <Footer version={version} />
    </>
  );
};

export default SessionListPri;
