import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import axios from "axios";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import parse from "html-react-parser";

const SubThemeDetails = () => {
  const [subThemes, setSubThemes] = useState([]);
  const { id } = useParams();
  const [subthemeName, setSubthemeName] = useState("");
  const [themeName, setThemeName] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubThemes = async () => {
      try {
        const response = await axios.get(
          ` http://161.97.81.168:8080/viewSubTheme/${id}`
        );
        const Data = response.data;
        setSubThemes(Data);
        console.log(Data);
      } catch (error) {
        console.error("Error fetching subthemes:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching activities
      }
    };

    const getThemeName = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getTheme/${id}`
        );
        setThemeName(response.data.title);
        console.log(response.data.title);
      } catch (error) {
        console.error("Error fetching theme:", error);
      }
    };

    getThemeName();
    fetchSubThemes();
  }, [id]);

  const handleBack = () => {
    navigate("/viewThemes");
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="sessions">
        <section className="content-header">
          <h3>Sub-Themes Contents for theme:...{themeName}</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/viewThemes">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">{themeName}</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="session-container">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-container">
            {loading ? ( // Check loading state
              <p>Loading...</p>
            ) : (
              <>
                {subThemes.length === 0 ? ( // Check if activities are empty
                  <div className="no-content-message">
                    <p>
                      No <i>content found for this page!</i>
                    </p>
                    <br></br>
                    <p>
                      Please click on the <b>+</b>Add button to add a
                      subtheme...
                    </p>
                  </div>
                ) : (
                  <ol className="list-item">
                    {subThemes.map((subtheme, index) => (
                      <li key={index} className="custom-list-item">
                        <strong>Sub-Theme Name:</strong> {parse(subtheme.title)}
                        <br></br>
                        <strong>Number of Weeks:</strong>{" "}
                        {parseInt(subtheme.duration)}-Weeks{""}
                        <br></br>
                        <strong>Learning Outcome:</strong>{" "}
                        {parse(subtheme.learning_outcome)}
                      </li>
                    ))}
                  </ol>
                )}
              </>
            )}
          </div>
        </div>
        <div className="submit-container">
          <button className="back" onClick={handleBack}>
            Back
          </button>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default SubThemeDetails;
