import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import { Schools, Teachers, Attendance } from "./Components/Administration";
import Resource from "./Resources/Resource";
import Notification from "./Components/Notification";
import AddSession from "./Sessions/AddSession";
import SessionListPri from "./Sessions/SessionListPri";
import SessionListSec from "./Sessions/SessionListSec";
import Settings from "./Components/Settings";
import SubjectCard from "./Subjects/SubjectCard";
import Science from "./Subjects/Science";
import AddActivityDetails from "./Sessions/AddActivityDetails";
import Register from "./Components/Register";
import Activity from "./Sessions/Activity";
import ActivityList from "./Sessions/ActivityList";
import AddActivity from "./Sessions/AddActivity";
import ActivityDetails from "./Sessions/ActivityDetails";
import SessionDetailsPri from "./Sessions/SessionDetailsPri";
import SessionDetailsSec from "./Sessions/SessionDetailsSec";
import ViewSecTopics from "./Topics/SecondaryTopics";
import ViewPriTopics from "./Topics/PrimaryTopics";
import { AddPriTopics } from "./Topics/PrimaryTopics";
import { AddSecTopics } from "./Topics/SecondaryTopics";
import { EditSecTopics } from "./Topics/SecondaryTopics";
import { EditPriTopics } from "./Topics/PrimaryTopics";
import { EditSession } from "./Sessions/AddSession";
import { EditSessionPri } from "./Sessions/AddSessionPri";
import AddSessionPri from "./Sessions/AddSessionPri";
import EditActivityDetails from "./Sessions/editActivity";
import EditSecActivity from "./Sessions/editSecActivity";
import UsersDetails from "./Components/UsersDetails";
import SignIn from "./Subjects/SignIn";
import TopicsList from "./Topics/TopicsList";
import AddTeacher from "./Subjects/AddTeacher";
import Theme from "./DearDay/Theme";
import AddTheme from "./DearDay/AddTheme";
import SUbThemes from "./DearDay/SUbThemes";
import AddSubTheme from "./DearDay/AddSubTheme";
import ArticleList from "./DearDay/ArticleList";
import AddArticle from "./DearDay/AddArticle";
import EditTheme from "./DearDay/EditTheme";
import EditSubTheme from "./DearDay/EditSubTheme";
import EditArticle from "./DearDay/EditArticle";
import ThemesList from "./DearDay/ThemesList";
import SubThemeDetails from "./DearDay/SubThemeDetails";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route index element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sections" element={<Resource />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin/schools" element={<Schools />} />
          <Route path="/viewTeachers" element={<Teachers />} />
          <Route path="/viewThemes" element={<Theme />} />
          <Route path="/ThemesList" element={<ThemesList />} />
          <Route path="/viewSubTheme/:theme_id" element={<SUbThemes />} />
          <Route path="/viewChapters/:sub_theme_id" element={<ArticleList />} />
          <Route path="/addTheme" element={<AddTheme />} />
          <Route path="/editTheme/:id" element={<EditTheme />} />
          <Route path="/editSubTheme/:id" element={<EditSubTheme />} />
          <Route path="/editArticle/:id" element={<EditArticle />} />
          <Route path="/addSubTheme/:theme_id" element={<AddSubTheme />} />
          <Route path="/subThemeDetails/:id" element={<SubThemeDetails />} />
          <Route path="/addArticle/:sub_theme_id" element={<AddArticle />} />
          <Route path="/addTeacher" element={<AddTeacher />} />
          <Route path="/admin/attendance" element={<Attendance />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/viewTopics" element={<TopicsList />} />
          <Route path="/viewSecTopics" element={<ViewSecTopics />} />
          <Route path="/viewPriTopics" element={<ViewPriTopics />} />
          <Route path="/addPriTopics" element={<AddPriTopics />} />
          <Route path="/updatePriTopic/:topicId" element={<EditPriTopics />} />
          <Route path="/addSecTopics" element={<AddSecTopics />} />
          <Route path="/updateSecTopic/:topicId" element={<EditSecTopics />} />
          <Route path="/addSessions/:topicId" element={<AddSession />} />
          <Route path="/addSession/:topicId" element={<AddSessionPri />} />
          <Route
            path="/sessiondetailsPri/:topicId"
            element={<SessionDetailsPri />}
          />
          <Route
            path="/sessiondetailsSec/:topicId"
            element={<SessionDetailsSec />}
          />
          <Route path="/editSecSession/:sessionId" element={<EditSession />} />
          <Route
            path="/editPriSession/:sessionId"
            element={<EditSessionPri />}
          />
          <Route
            path="/viewPriSessions/:topicId"
            element={<SessionListPri />}
          />
          <Route
            path="/viewSecSessions/:topicId"
            element={<SessionListSec />}
          />
          <Route path="/add-activity/:sessionId" element={<AddActivity />} />
          <Route path="/activity-list/:sessionId" element={<Activity />} />
          <Route path="/activityList/:sessionId" element={<ActivityList />} />
          <Route
            path="/add-activitydetails/:sessionId"
            element={<AddActivityDetails />}
          />
          <Route
            path="/activitydetails/:activityId"
            element={<ActivityDetails />}
          />
          <Route
            path="/editActivityDetails/:activityId"
            element={<EditActivityDetails />}
          />
          <Route
            path="/editSecActivity/:activityId"
            element={<EditSecActivity />}
          />
          <Route path="/sections/science" element={<Science />} />
          <Route path="/sections/subjects" element={<SubjectCard />} />
          <Route path="/userDetails/:userId" element={<UsersDetails />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
