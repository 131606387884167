import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import axios from "axios";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import parse from "html-react-parser";

const SessionDetailsSec = () => {
  const [sessions, setSessions] = useState([]);
  const { topicId } = useParams();
  const [topicName, setTopicName] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/viewSessions/${topicId}`
        );
        console.log(response.data); // Log the response data
        setSessions(response.data);
      } catch (error) {
        console.error("Error fetching sessions:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching activities
      }
    };

    const getTopicName = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getTopic/${topicId}`
        );
        setTopicName(response.data.topicName);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    getTopicName();
    fetchSessions();
  }, [topicId]);

  const handleBack = () => {
    navigate("/viewSecTopics");
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="sessions">
        <section className="content-header">
          <h3>Session Contents for topic:...{topicName}</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/viewSecTopics">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">{topicName}</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="session-container">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-container">
            {loading ? ( // Check loading state
              <p>Loading...</p>
            ) : (
              <>
                {sessions.length === 0 ? ( // Check if activities are empty
                  <div className="no-content-message">
                    <p>
                      No <i>content found for this page!</i>
                    </p>
                    <br></br>
                    <p>
                      Please click on the <b>+</b>Add button to add a Session...
                    </p>
                  </div>
                ) : (
                  <ol className="list-item">
                    {sessions.map((session, index) => (
                      <li key={index} className="custom-list-item">
                        <strong>Session Name:</strong>{" "}
                        {parse(session.sessionName)}
                        <br></br>
                        <strong>Tools provided by Fundi Bots:</strong>{" "}
                        {parse(session.fundibotsResources)}
                        <strong>
                          Tools provided by the School/Learners:
                        </strong>{" "}
                        {parse(session.schoolResources)}
                        <strong>SESSION PLAN:</strong>
                        <br></br>
                        <strong>Duration:</strong> {parse(session.duration)}
                        -Minutes{""}
                        <br></br>
                        <strong>Objectives:</strong>{" "}
                        {parse(session.learningObjective)}
                      </li>
                    ))}
                  </ol>
                )}
              </>
            )}
          </div>
        </div>

        <div className="submit-container">
          <button type="submit" className="edittopic">
            <Link to={`/addSessions/${topicId}`} className="link">
              {" "}
              Submit
            </Link>
          </button>
          <button className="back" onClick={handleBack}>
            Back
          </button>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default SessionDetailsSec;
