import React from "react";
import { useState, useEffect } from "react";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SideBar from "../Components/SideBar";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const AddTheme = () => {
  const [themeCode, setThemeCode] = useState("");
  const [title, setTitle] = useState("");
  const [classTaught, setClassTaught] = useState("");
  const [term, setTerm] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post("http://161.97.81.168:8080/addTheme/", {
          title: title,
          themeCode: themeCode,
          term: term,
          classTaught: classTaught,
        })
        .then(function (res) {
          console.log("Response", res);

          navigate("/viewPriTopics");
        });
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Add Theme</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/viewThemes">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Science</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to="/viewThemes">
            <FaListUl className="icon" />
            View Themes
          </Link>{" "}
          |
          <Link to="/addTheme">
            <FaPlus className="icon" />
            Add Theme
          </Link>
        </div>
        <br></br>
        <div className="form-container">
          {/* <div className="topicslist"> */}
          <div className="col-md-3">
            <SideBar />
          </div>
          <form className="form-submit" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="themeCode">Theme Code:</label>
              <input
                type="text"
                id="themeCode"
                name="themeCode"
                value={themeCode}
                maxLength={4}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 4) {
                    setThemeCode(inputValue);
                  } else {
                    console.log("Topic code must be exactly 5 characters.");
                  }
                }}
                required
              />
            </div>
            <div>
              <label htmlFor="name">Theme Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="classTaught">Class:</label>
              <select
                id="classTaught"
                name="classTaught"
                value={classTaught}
                onChange={(e) => setClassTaught(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select Class
                </option>
                <option value="P.4">P.4</option>
                <option value="P.5">P.5</option>
                <option value="P.6">P.6</option>
                <option value="P.7">P.7</option>
              </select>
            </div>
            <div>
              <label htmlFor="term">Term:</label>
              <select
                id="term"
                name="term"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              >
                <option value="" disabled selected>
                  Choose Term
                </option>
                <option value="Term I">Term I</option>
                <option value="Term II">Term II</option>
                <option value="Term III">Term III</option>
              </select>
            </div>
            <div className="edittopics" style={{ margin: "0" }}>
              <button type="submit" className="edittopic">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddTheme;
