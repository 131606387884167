import React, { useState } from "react";

const BooleanCheckbox = ({ onCheckboxChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    setIsChecked(newChecked);
    onCheckboxChange(!newChecked); // Invert the checked state
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          style={{marginRight: "20px", width: "20px", marginLeft: "0", height: "30px"}}
        />
      </label>
      {/* <p>The checkbox is {isChecked ? 'checked' : 'unchecked'}</p> */}
    </div>
  );
};

export default BooleanCheckbox;
