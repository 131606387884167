import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setToken } from "../Components/token";

const AddArticle = () => {
  const { sub_theme_id } = useParams();
  const [percentage, setPercentage] = useState(0);
  const cancelUpload = useRef(null);
  const [articleTitle, setArticleTitle] = useState("");
  const [article, setArticle] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [uploadDone, setUploadDone] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [subthemes, setSubthemes] = useState([]);
  const navigate = useNavigate();

  // Fetch sub-theme details
  useEffect(() => {
    const fetchSubThemes = async () => {
      try {
        const response = await axios.get(
          ` http://161.97.81.168:8080/getSubTheme/${sub_theme_id}`
        );
        console.log("Sub-Theme: ", response.data); // Log the response data
        setSubthemes(response.data);
      } catch (error) {
        console.error("Error fetching subthemes:", error);
      }
    };

    fetchSubThemes();
  }, [sub_theme_id]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();
      formData.append("title", articleTitle);
      formData.append("sub_theme", subthemes.id);
      formData.append("article", article);
      formData.append("time", selectedTime);

      // Make POST request to upload media
      const response = await axios.post(
        "http://161.97.81.168:8080/addChapter/",
        formData,
        {
          onUploadProgress: (data) => {
            setPercentage(Math.round((100 * data.loaded) / data.total));
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelUpload.current = cancel)
          ),
        }
      );
      setUploadDone(true);
      console.log("Media uploaded successfully:", response.data);
      setToken(response.data.token);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const handleCancelUpload = () => {
    if (cancelUpload.current) cancelUpload.current("");
    setClicked(true);
    setPercentage(0);
  };

  const handleBack = () => {
    navigate(`/viewChapters/${sub_theme_id}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="sessions">
        <h3>Add Article: [{subthemes.title}]</h3>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="adddetails-tab">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-addactivitydetails">
            <div className="activity-row">
              <h2>Theme Articles:</h2>
              <div className="center">
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <label className="form-label" htmlFor="articleTitle);">
                        Article Title:
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        id="articleTitle);"
                        value={articleTitle}
                        onChange={(e) => setArticleTitle(e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <label className="form-label" htmlFor="session">
                        Sub-Theme:
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        id="session"
                        value={subthemes.title}
                        readOnly
                        required
                      />
                    </div>

                    <div>
                      <label className="form-label" htmlFor="selectedTime">
                        Time Allocation:
                      </label>
                      <select
                        id="selectedTime"
                        name="selectedTime"
                        value={selectedTime}
                        onChange={(e) => setSelectedTime(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Time
                        </option>
                        <option value="05">05 mins</option>
                        <option value="10">10 mins</option>
                        <option value="15">15 mins</option>
                        <option value="20">20 mins</option>
                        <option value="25">25 mins</option>
                      </select>
                    </div>

                    <div>
                      <label className="form-label" htmlFor="videofile">
                        File:
                      </label>
                      <div className="videofile">
                        <input
                          type="file"
                          accept=".pdf, image/*"
                          onChange={(e) => setArticle(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-container">
                    <button type="submit" className="edittopic">
                      Upload
                    </button>
                    <button type="button" className="back" onClick={handleBack}>
                      Back
                    </button>
                  </div>
                  <div className="progress-bar">
                    <span>{`${percentage}%`}</span>
                    <progress
                      className="progress"
                      value={percentage}
                      max="100"
                      style={{ borderRadius: "8px" }}
                    />
                    {uploadDone && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="tick-icon"
                        style={{ pointerEvents: "none" }}
                      />
                    )}

                    {!uploadDone && (
                      <>
                        {article && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCancelUpload}
                            style={{
                              cursor: "pointer",
                              color: clicked ? "red" : "blue",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddArticle;
