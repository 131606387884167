import React, { useState, useRef, useEffect } from "react";
import {
  FaBell,
  FaCaretUp,
  FaEnvelope,
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaCaretDown,
  FaBars,
} from "react-icons/fa";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";
import user from "../Assets/user.png";
import Notification from "./Notification";
import { getToken, setToken } from "./token";

const client = axios.create({
  baseURL: "http://161.97.81.168:8080/",
});

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();
  const profileDropdownRef = useRef(null);
  const notificationRef = useRef(null);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleNotificationToggle = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClickOutside = (event) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleLogout(e) {
    e.preventDefault();
    const userToken = getToken();
    console.log("initiated:", userToken);
    client
      .post("logout/", null, {
        headers: {
          Authorization: `Token ${userToken}`,
        },
      })
      .then(function (res) {
        localStorage.removeItem("Token");
        setCurrentUser(false);
        setToken(null);
        localStorage.clear();
        console.log("Logged out...");
        console.log(res.data); // Optionally, log the response data
        navigate("/login");
      })
      .catch(function (error) {
        console.error("Logout failed:", error);
      });
  }

  return (
    <div>
      <nav className="navbar">
        <div>
          <button className="btn btn-primary menu">
            <FaBars />
          </button>
        </div>
        <div className="nav-icons">
          <span
            className="notification-icon"
            onClick={handleNotificationToggle}
          >
            <FaBell />
          </span>
          {showNotifications && (
            <div className="notification-dropdown" ref={notificationRef}>
              <Notification />
            </div>
          )}
          <span className="inbox-icon">
            <Link to="#" className="dropdown-link">
              <FaEnvelope />
            </Link>
          </span>
          <div className="dropdown" ref={profileDropdownRef}>
            <div
              className="dropdown-toggle"
              onClick={handleDropdownToggle}
              drop="down-start"
            >
              <img className="user-avatar" src={user} alt="User" />
              {showDropdown ? (
                <FaCaretUp className="caret-down" />
              ) : (
                <FaCaretDown className="caret-down" />
              )}
            </div>
            {showDropdown && (
              <div className="dropdown-menu">
                <div className="dropdown-item">
                  <Link to={`/userDetails/${userId}`} className="dropdown-link">
                    <FaUser className="fa-fw" /> Profile
                  </Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/settings" className="dropdown-link">
                    <FaCog className="fa-fw" /> Settings
                  </Link>
                </div>
                <div className="dropdown-divider" />
                <div className="dropdown-item">
                  <Link className="dropdown-link" onClick={handleLogout}>
                    <FaSignOutAlt className="fa-fw" /> Logout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
