import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import ReactQuill from "react-quill";
import axios, { CancelToken, isCancel } from "axios";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-quill/dist/quill.snow.css";

const EditSecActivity = () => {
  const { activityId } = useParams();

  const [activity, setActivity] = useState([]);
  const [activityTitle, setActivityTitle] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [teachersActivity, setTeachersActivity] = useState("");
  const [studentsActivity, setStudentsActivity] = useState("");
  const [notes, setNotes] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [uploadDone, setUploadDone] = useState(false);
  const [clicked, setClicked] = useState(false);
  const cancelUpload = useRef(null);
  const navigate = useNavigate();

  const [mediaType, setMediaType] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [isNewVideoSelected, setIsNewVideoSelected] = useState(false);
  const [realvideo, setRealvideo] = useState(""); 

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
      ["link", "image", "video"],
    ],
  };

  // fetch activity
  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getActivity/${activityId}`
        );
        const activityData = response.data;
        setActivity(activityData);
        setActivityTitle(activityData.title);
        setSelectedTime(activityData.time);
        setTeachersActivity(activityData.teacherActivity);
        setStudentsActivity(activityData.studentActivity);
        setNotes(activityData.notes);
        setImageTitle(activityData.image_title);
        setVideoTitle(activityData.video_title);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchActivity();
  }, [activityId]);

  const updateActivity = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();

      if (activity.mediaType === "text") {
        formData.append("title", activityTitle);
        formData.append("session", activity.session);
        formData.append("teacherActivity", teachersActivity);
        formData.append("studentActivity", studentsActivity); // Append the file with the correct field name
        formData.append("time", selectedTime);
        formData.append("notes", notes);
        formData.append("mediaType", "text");
      } else if (activity.mediaType === "image") {
        formData.append("image", image);
        formData.append("title", activityTitle);
        formData.append("session", activity.session);
        formData.append("image_title", imageTitle); // Append the file with the correct field name
        formData.append("time", selectedTime);
        formData.append("mediaType", "image");
      } else if (activity.mediaType === "video") {
        formData.append("title", activityTitle);
        formData.append("session", activity.session);
        formData.append("video", video);
        formData.append("real_video", realvideo); 
        formData.append("video_title", videoTitle); // Append the file with the correct field name
        formData.append("time", selectedTime);
        formData.append("mediaType", "video");
      }

      // Make POST request to upload media
      const response = await axios.put(
        `http://161.97.81.168:8080/updateActivity/${activityId}`,
        formData,
        {
          onUploadProgress: (data) => {
            setPercentage(Math.round((100 * data.loaded) / data.total));
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelUpload.current = cancel)
          ),
        }
      );
      setUploadDone(true);
      console.log("Media uploaded successfully:", response.data);
      alert("Activity Updated successfully!");

      navigate(`/activityList/${activity.session}`);
      // Handle success response
    } catch (error) {
      console.error("Error uploading media:", error);
      // Handle error
    }
  };

  const handleVideoChange = (e) => {
    setVideo(e.target.files[0]);
    setIsNewVideoSelected(true);
  };
  
  const handleCancelUpload = () => {
    if (cancelUpload.current) cancelUpload.current("");
    setClicked(true);
    setPercentage(0);
  };

  const handleBack = () => {
    navigate(`/activityList/${activity.session}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="sessions">
        <h3>Edit::: {activity.title}</h3>
        <div className="adddetails-tab">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-addactivitydetails">
            <div className="activity-row">
              <h2>Learning Activities:</h2>
              <div className="center">
                <form onSubmit={updateActivity}>
                  {activity.mediaType === "text" && (
                    <div>
                      <div>
                        <label className="form-label" htmlFor="activityTitle">
                          Activity Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="activityTitle"
                          value={activityTitle}
                          onChange={(e) => setActivityTitle(e.target.value)}
                          required
                        />
                      </div>

                      <div>
                        <label className="form-label" htmlFor="teacherActivity">
                          Teachers Activities:
                        </label>
                        <div className="wrapper">
                          <ReactQuill
                            className="ql-editor"
                            style={{
                              height: "200px",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            theme="snow"
                            value={teachersActivity}
                            onChange={(value) => setTeachersActivity(value)}
                            modules={modules}
                            required
                          />
                        </div>
                      </div>

                      <div>
                        <label className="form-label" htmlFor="studentActivity">
                          Learners Activities:
                        </label>
                        <div className="wrapper">
                          <ReactQuill
                            className="ql-editor"
                            style={{
                              height: "200px",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            theme="snow"
                            value={studentsActivity}
                            onChange={(value) => setStudentsActivity(value)}
                            modules={modules}
                            required
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          className="form-label"
                          htmlFor="selectedTimeText"
                        >
                          Time Allocation:
                        </label>
                        <select
                          id="selectedTimeText"
                          name="selectedTimeText"
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                        >
                          <option value="" disabled>
                            {activity.time}
                          </option>
                          <option value="05">05 mins</option>
                          <option value="10">10 mins</option>
                          <option value="15">15 mins</option>
                          <option value="20">20 mins</option>
                          <option value="25">25 mins</option>
                        </select>
                      </div>

                      <div>
                        <label className="form-label" htmlFor="notes">
                          Notes:
                        </label>
                        <div className="wrapper">
                          <ReactQuill
                            className="ql-editor"
                            style={{
                              height: "200px",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            theme="snow"
                            value={notes}
                            onChange={(value) => setNotes(value)}
                            modules={modules}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {activity.mediaType === "image" && (
                    <div>
                      <div>
                        <label className="form-label" htmlFor="activityTitle">
                          Activity Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="activityTitle"
                          value={activityTitle}
                          onChange={(e) => setActivityTitle(e.target.value)}
                          required
                          placeholder={activity.title}
                        />
                      </div>

                      <div>
                        <label className="form-label" htmlFor="imageTitle">
                          File Name:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="imageTitle"
                          value={imageTitle}
                          onChange={(e) => setImageTitle(e.target.value)}
                          required
                          placeholder={activity.image_title}
                        />
                      </div>

                      <div>
                        <label
                          className="form-label"
                          htmlFor="selectedTimeVideo"
                        >
                          Estimated Time:
                        </label>
                        <select
                          id="selectedTimeVideo"
                          name="selectedTimeVideo"
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                        >
                          <option value="" disabled>
                            {activity.time}
                          </option>
                          <option value="05">05 mins</option>
                          <option value="10">10 mins</option>
                          <option value="15">15 mins</option>
                          <option value="20">20 mins</option>
                          <option value="25">25 mins</option>
                        </select>
                      </div>
                      <label>Image:</label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])}
                        required
                      />
                    </div>
                  )}

                  {activity.mediaType === "video" && (
                    <div>
                      <div>
                        <label className="form-label" htmlFor="activityTitle">
                          Activity Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="activityTitle"
                          value={activityTitle}
                          onChange={(e) => setActivityTitle(e.target.value)}
                          required
                          placeholder={activity.title}
                        />
                      </div>

                      <div>
                        <label className="form-label" htmlFor="fileName">
                          File Name:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="videoTitle"
                          value={videoTitle}
                          onChange={(e) => setVideoTitle(e.target.value)}
                          required
                          placeholder={activity.video_title}
                        />
                      </div>

                      <div>
                        <label
                          className="form-label"
                          htmlFor="selectedTimeVideo"
                        >
                          Estimated Time:
                        </label>
                        <select
                          id="selectedTimeVideo"
                          name="selectedTimeVideo"
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                        >
                          <option value="" disabled>
                            {activity.time}
                          </option>
                          <option value="05">05 mins</option>
                          <option value="10">10 mins</option>
                          <option value="15">15 mins</option>
                          <option value="20">20 mins</option>
                          <option value="25">25 mins</option>
                        </select>
                      </div>

                      <div>
                      <label className="form-label" htmlFor="videofile">
                          Video:
                        </label>
                      {activity.video && !isNewVideoSelected && (
                        <video
                          controls
                          className="img-fluid"
                          style={{
                            width: "300px",
                            borderRadius: "4px",
                            alignItems: "center",
                            marginTop: "auto",
                          }}
                        >
                          <source
                            src={`http://161.97.81.168:8080${activity.video}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}

                      <div className="videofile">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoChange}
                      />
                      <select
                          id="selectedvideo"
                          name="selectedvideo"
                          value={realvideo}
                          onChange={(e) => setRealvideo(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select VideoType
                          </option>
                          <option value="realvideo">Actual Video</option>
                          <option value="placeholder">Placeholder</option>
                        </select>
                      </div>
                      </div>
                    </div>
                  )}
                  <div className="submit-container">
                    <button type="submit" className="edittopic">
                      Update
                    </button>
                    <button className="back" onClick={handleBack}>
                      Back
                    </button>
                  </div>
                  <div className="progress-bar">
                    <span>{`${percentage}%`}</span>
                    <progress
                      className="progress"
                      value={percentage}
                      max="100"
                      style={{ borderRadius: "8px" }}
                    />
                    {uploadDone && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="tick-icon"
                        style={{ pointerEvents: "none" }}
                      />
                    )}

                    {!uploadDone && (
                      <>
                        {image && mediaType === "image" && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCancelUpload}
                            style={{
                              cursor: "pointer",
                              color: clicked ? "red" : "blue",
                            }}
                          />
                        )}
                        {video && mediaType === "video" && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCancelUpload}
                            style={{
                              cursor: "pointer",
                              color: clicked ? "red" : "blue",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default EditSecActivity;
