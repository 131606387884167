import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaHome,
  FaUsers,
  FaChalkboardTeacher,
  FaBars,
  FaClock,
  FaSchool,
  FaCalendarCheck,
  FaUserTie,
} from "react-icons/fa";
import logo from "../Assets/fb.png";

const SideBar = () => {
  const location = useLocation();

  return (
    <div className="sidebar">
      <div className="navbar-brand">
        <Link className="logo" to="/dashboard">
          <img
            src={logo}
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
              width: "3rem",
              height: "3rem",
            }}
            alt="fb_logo"
          />
        </Link>
        <h3>
          <Link to="/dashboard" className="link">
            <i>VirtualFundi</i>
          </Link>
        </h3>
      </div>
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/dashboard" className="link">
              <li
                className={`sidebarListItem ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
              >
                <FaHome className="sidebarIcon" />
                Home
              </li>
            </Link>
            <li
              className={`sidebarListItem ${
                location.pathname === "/analytics" ? "active" : ""
              }`}
            >
              <FaClock className="sidebarIcon" />
              Analytics
            </li>
            <li
              className={`sidebarListItem ${
                location.pathname === "/users" ? "active" : ""
              }`}
            >
              <FaUsers className="sidebarIcon" />
              Users
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Resources</h3>
          <ul className="sidebarList">
            <Link to="/viewPriTopics" className="link">
              <li
                className={`sidebarListItem ${
                  location.pathname === "/viewPriTopics" ? "active" : ""
                }`}
              >
                <FaChalkboardTeacher className="sidebarIcon" />
                Primary
              </li>
            </Link>
            <Link to="/viewSecTopics" className="link">
              <li
                className={`sidebarListItem ${
                  location.pathname === "/viewSecTopics" ? "active" : ""
                }`}
              >
                <FaChalkboardTeacher className="sidebarIcon" />
                Secondary
              </li>
            </Link>
            <Link to="/viewThemes" className="link">
              <li
                className={`sidebarListItem ${
                  location.pathname === "/viewThemes" ? "active" : ""
                }`}
              >
                <FaBars className="sidebarIcon" />
                DEAR Day
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Manage</h3>
          <ul className="sidebarList">
            <Link to="/viewTeachers" className="link">
              <li
                className={`sidebarListItem ${
                  location.pathname === "/viewTeachers" ? "active" : ""
                }`}
              >
                <FaUserTie className="sidebarIcon" />
                Teachers
              </li>
            </Link>
            <li
              className={`sidebarListItem ${
                location.pathname === "/schools" ? "active" : ""
              }`}
            >
              <FaSchool className="sidebarIcon" />
              Schools
            </li>
            <li
              className={`sidebarListItem ${
                location.pathname === "/attendance" ? "active" : ""
              }`}
            >
              <FaCalendarCheck className="sidebarIcon" />
              Attendance
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
