import { useEffect, useState } from "react";
import { FaPlus, FaListUl, FaHome, FaTrash, FaEdit } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

const maxLength = 10;

export default function ArticleList() {
  const [articles, setArticles] = useState([]);
  const [data, setData] = useState([]);
  const [subthemeName, setSubthemeName] = useState("");
  const [themeName, setThemeName] = useState("");
  const [subthemes, setSubthemes] = useState([]);
  const { sub_theme_id } = useParams();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchChapters = async () => {
    try {
      const response = await axios.get(
        `http://161.97.81.168:8080/viewChapters/${sub_theme_id}`
      );
      setArticles(response.data);
      console.log("Articles: ", response.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching articles
    }
  };

  useEffect(() => {
    const fetchSubThemes = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSubTheme/${sub_theme_id}`
        );
        setSubthemes(response.data);
        console.log("Sub-Theme: ", response.data); // Log the response data
        setSubthemeName(response.data.title);
        console.log("Sub-ThemeName:", response.data.title);
      } catch (error) {
        console.error("Error fetching subthemes:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching articles
      }
    };

    fetchSubThemes();
    fetchChapters();
  }, [sub_theme_id]);

  const themeItem = data.find((item) => item.id === subthemes.theme);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.get(
          "http://161.97.81.168:8080/viewTheme/"
        );
        const themeData = response.data;
        setData(themeData);

        // Assuming the correct theme is the one associated with the subtheme
        const currentTheme = themeData.find(
          (theme) => theme.id === subthemes.theme
        );
        if (currentTheme) {
          setThemeName(currentTheme.title);
          console.log("ThemeName: ", currentTheme.title);
        }

        console.log("Themes: ", themeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchThemes();
  }, [subthemes.theme]); // Add subthemes.theme as a dependency

  const handleDelete = async (id) => {
    setIdToDelete(id);
    setShowConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      // Perform the delete operation
      await axios.delete(
        `http://161.97.81.168:8080/deleteCapter/${idToDelete}`
      );
      // After successful deletion, hide the confirmation dialog and reload the data
      setShowConfirmation(false);
      console.log("Item Deleted Successfully!");
      setIdToDelete(null); // Reset the ID to delete
      fetchChapters(); // Re-fetch articles to update the UI
    } catch (err) {
      console.log(err);
    }
  };

  const cancelDelete = () => {
    // If user cancels, hide the confirmation dialog and reset the ID to delete
    setShowConfirmation(false);
    setIdToDelete(null);
  };

  const handleUpdateChapter = (sub_theme_id) => {
    navigate(`/editArticle/${sub_theme_id}`);
  };

  const handleBack = () => {
    navigate(`/viewSubTheme/${subthemes.theme}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>
            {" "}
            Chapters for - {""}
            {subthemeName.length > 61
              ? subthemeName.substring(0, 61) + "..."
              : subthemeName}
          </h3>

          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              {themeItem && (
                <li>
                  <Link to="/viewThemes">{themeName}</Link>{" "}
                  {/* Use themeName here */}
                </li>
              )}

              <li>
                <Link to={`/viewSubTheme/${subthemes.theme}`}>
                  {subthemeName.substring(0, maxLength)}
                </Link>
              </li>
              <li className="active">Chapters</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={`/viewSubTheme/${subthemes.theme}`}>
            <FaListUl className="icon" />
            View Sub-Themes
          </Link>{" "}
          |
          <Link to={`/addArticle/${sub_theme_id}`}>
            <FaPlus className="icon" />
            Add Article
          </Link>
        </div>
        <br></br>
        <div className="activity-tab"></div>
        <div className="col-md-3">
          <SideBar />
        </div>
        {loading ? ( // Check loading state
          <p>
            <b>Loading...</b>
          </p>
        ) : (
          <>
            {articles.length === 0 ? ( // Check if articles are empty
              <div className="no-content-message">
                <p>
                  No <i>content found for this page!</i>
                </p>
                <br></br>
                <p>
                  Please click on the <b>+</b>Add button to add content...
                </p>
              </div>
            ) : (
              <div>
                <table className="table-container">
                  <thead>
                    <tr>
                      <th>Chapter Title</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articles.map((activity) => (
                      <tr key={activity.id}>
                        <td>
                          <button
                            onClick={(e) => {
                              e.preventDefault(); // Prevent any default behavior

                              const width = 800;
                              const height = 600;
                              const left = window.screen.width / 2 - width / 2;
                              const top = window.screen.height / 2 - height / 2;

                              window.open(
                                `http://161.97.81.168:8080${activity.article}`,
                                "popupWindow",
                                `width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,status=no`
                              );
                            }}
                            style={{
                              background: "none",
                              border: "none",
                              color: "#545353",
                              textDecoration: "none",
                              cursor: "pointer",
                              fontSize: "large",
                              padding: "5px",
                            }}
                          >
                            {activity.title}
                          </button>
                        </td>
                        <td className="operations">
                          <button
                            className="update_activity"
                            onClick={() => handleUpdateChapter(activity.id)}
                          >
                            <FaEdit />
                          </button>
                          <button
                            className="deleteactivity"
                            onClick={() => handleDelete(activity.id)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}

        <div className="submit_container">
          <button className="back" onClick={handleBack}>
            Back
          </button>
        </div>
        {/* Confirmation dialog */}
        {showConfirmation && (
          <>
            <div className="overlay"></div>
            <div className="confirmation-dialog">
              <p className="confirmation-message">
                Are you sure you want to delete?
              </p>
              <div className="confirmation-buttons">
                <button className="confirm-button" onClick={confirmDelete}>
                  Yes
                </button>
                <button className="cancel-button" onClick={cancelDelete}>
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer version={version} />
    </>
  );
}
