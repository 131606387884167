import React from "react";
import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import Footer from "./Footer";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, setToken } from "./token";

export const Administration = () => {
  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="home">
        <h1>Administration</h1>
        <div className="cards-container">
          <div className="col-md-3">
            <SideBar />
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export const Schools = () => {
  const version = "1.0.0";
  return (
    <>
      <Navbar />
      <div className="home">
        <h1>Schools</h1>
        <div className="cards-container">
          <div className="col-md-3">
            <SideBar />
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export const Teachers = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeachers = async () => {
      const token = getToken();
      console.log("initiated:", token);
      try {
        const response = await axios.get(
          "http://161.97.81.168:8080/viewTeachers"
        );
        setTeachers(response.data);
        console.log(response.data);
        setToken(null);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTeachers();
  }, []);

  const handleDelete = async (id) => {
    setIdToDelete(id);
    setShowConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      // Perform the delete operation
      await axios.delete(
        `http://161.97.81.168:8080/deleteActivity/${idToDelete}`
      );
      // After successful deletion, hide the confirmation dialog and reload the page or navigate
      setShowConfirmation(false);
      window.location.reload(); // Or navigate to the desired route
    } catch (err) {
      console.log(err);
    }
  };

  const cancelDelete = () => {
    // If user cancels, hide the confirmation dialog and reset the ID to delete
    setShowConfirmation(false);
    setIdToDelete(null);
  };

  const handleUpdateActivity = (sessionId) => {
    navigate(`/editActivityDetails/${sessionId}`);
    console.log("Add session for topic with code:", sessionId);
  };

  const version = "1.0.0";
  return (
    <>
      <Navbar />
      <div className="teachers">
        <section className="content-header">
          <h3>Teachers List</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Teachers</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to="/viewTeachers">
            <FaListUl className="icon" />
            View Teachers
          </Link>
          |
          <Link to="/addTeacher">
            <FaPlus className="icon" />
            Add Teacher
          </Link>
        </div>
        <br></br>
        <div className="teacherlist">
          <div className="col-md-3">
            <SideBar />
          </div>
          {loading ? ( // Check loading state
            <p>
              <b>Loading...</b>
            </p>
          ) : (
            <>
              {teachers.length === 0 ? ( // Check if activities are empty
                <div className="no-content-message">
                  <p>
                    No <i>content found for this page!</i>
                  </p>
                  <br></br>
                  <p>
                    Please click on the <b>+</b>Add button to add content...
                  </p>
                </div>
              ) : (
                <table className="table-container">
                  <thead>
                    <tr>
                      <th>Staff Name</th>
                      <th>School</th>
                      <th>Class</th>
                      <th>Contact</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teachers.map((item) => (
                      <tr key={item.id}>
                        <td>{item.teachersName}</td>
                        <td>{item.schoolName}</td>
                        <td>{item.classTaught}</td>
                        <td>{item.phoneName}</td>
                        <td className="action-column">
                          <button
                            className="edit"
                            onClick={() => handleUpdateActivity(item.id)}
                          >
                            Update
                          </button>
                          <button
                            className="deleteactivity"
                            onClick={() => handleDelete(item.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
        {showConfirmation && (
          <>
            <div className="overlay"></div>
            <div className="confirmation-dialog">
              <p className="confirmation-message">
                Are you sure you want to delete?
              </p>
              <div className="confirmation-buttons">
                <button className="confirm-button" onClick={confirmDelete}>
                  Yes
                </button>
                <button className="cancel-button" onClick={cancelDelete}>
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer version={version} />
    </>
  );
};

export const Attendance = () => {
  const version = "1.0.0";
  return (
    <>
      <Navbar />
      <div className="home">
        <h1>Attendance</h1>
        <div className="cards-container">
          <div className="col-md-3">
            <SideBar />
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};
