import React, { useState } from "react";
import "./SignIn.css";
import axios from "axios";
import { setToken } from "../Components/token";
import { useNavigate } from "react-router-dom";

const client = axios.create({
  baseURL: "http://161.97.81.168:8080/",
});

const SignIn = () => {
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const toggleForm = () => {
    setIsLoginFormVisible(!isLoginFormVisible);
  };

  function submitLogin(e) {
    e.preventDefault();
    client
      .post("login/", {
        username: username,
        password: password,
      })
      .then(function (res) {
        console.log("Login successful:", res.data);
        setToken(res.data.token);
        navigate("/dashboard");
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          console.log("Invalid username or password", error);
          setError("Invalid username or password");
        } else {
          console.error("Login error:", error);
          setError("An error occurred. Please try again later.");
        }
      });
  }

  function submitRegistration(e) {
    e.preventDefault();
    client
      .post("register/", {
        username: username,
        password: password,
        email: email,
        school: school,
        subject: subject,
      })
      .then(function (res) {
        navigate("/login");
      })
      .catch(function (error) {
        console.error("Signup error:", error);
        setError("An error occurred. Please try again later.");
        alert(error);
      });
  }

  return (
    <div className="modula">
      <div className="module form-module" style={{ borderRadius: "4px" }}>
        {error && <p className="error-message">{error}</p>}
        <div className="toggle" onClick={toggleForm}>
          <i
            className={`fa fa-times ${isLoginFormVisible ? "fa-pencil" : ""}`}
          ></i>
          {/* <div className="tooltip">Click Me</div> */}
        </div>
        <div
          className="form"
          style={{ display: isLoginFormVisible ? "block" : "none" }}
        >
          <h1>
            <i>VirtualFundi</i>
          </h1>
          <h2>Login to your account</h2>
          <form onSubmit={submitLogin}>
            <div className="form-login">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-login">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button>Login</button>
          </form>
        </div>
        <div
          className="form"
          style={{ display: isLoginFormVisible ? "none" : "block" }}
        >
          <h2>Create an account</h2>
          <form onSubmit={submitRegistration}>
            <div>
              <label>Username:</label>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Confirm Password:</label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button>Register</button>
          </form>
        </div>
        <div className="cta">
          <a href="#">Forgot your password?</a>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
