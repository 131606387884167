import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditArticle = () => {
  const { id } = useParams();
  const [articles, setArticles] = useState({});
  const [percentage, setPercentage] = useState(0);
  const cancelUpload = useRef(null);
  const [articleTitle, setArticleTitle] = useState("");
  const [article, setArticle] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [uploadDone, setUploadDone] = useState(false);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChapter = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getChapter/${id}`
        );
        const chapter = response.data;
        setArticles(chapter);
        setArticleTitle(chapter.title);
        setSelectedTime(chapter.selectedTime);
        setArticle(chapter.article);
        console.log("Article: ", chapter);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };
    fetchChapter();
  }, [id]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("title", articleTitle);
      formData.append("article", article);
      const res = await axios.put(
        `http://161.97.81.168:8080/updateChapter/${id}`,
        formData,
        {
          onUploadProgress: (data) => {
            setPercentage(Math.round((100 * data.loaded) / data.total));
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelUpload.current = cancel)
          ),
        }
      );

      setUploadDone(true);
      alert("Chapter Updated Successfully!");
      navigate(`/viewChapters/${articles.sub_theme}`);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleCancelUpload = () => {
    if (cancelUpload.current) cancelUpload.current("");
    setClicked(true);
    setPercentage(0);
  };

  const handleBack = () => {
    navigate(`/viewChapters/${articles.sub_theme}`);
  };
  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="sessions">
        <h3>Edit Chapter: {articles.title}</h3>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="adddetails-tab">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-addactivitydetails">
            <div className="activity-row">
              <h2>Edit Theme Chapter:</h2>
              <div className="center">
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <label className="form-label" htmlFor="articleTitle">
                        Article Title:
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        id="articleTitle"
                        value={articleTitle}
                        onChange={(e) => setArticleTitle(e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <label className="form-label" htmlFor="selectedTime">
                        Time Allocation:
                      </label>
                      <select
                        id="selectedTime"
                        name="selectedTime"
                        value={selectedTime}
                        onChange={(e) => setSelectedTime(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Time
                        </option>
                        <option value="05">05 mins</option>
                        <option value="10">10 mins</option>
                        <option value="15">15 mins</option>
                        <option value="20">20 mins</option>
                        <option value="25">25 mins</option>
                      </select>
                    </div>

                    <div>
                      <label className="form-label" htmlFor="articlefile">
                        File:
                      </label>
                      <div className="articlefile">
                        <input
                          type="file"
                          accept=".pdf, image/*"
                          onChange={(e) => setArticle(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-container">
                    <button type="submit" className="edittopic">
                      Update
                    </button>
                    <button type="button" className="back" onClick={handleBack}>
                      Back
                    </button>
                  </div>
                  <div className="progress-bar">
                    <span>{`${percentage}%`}</span>
                    <progress
                      className="progress"
                      value={percentage}
                      max="100"
                      style={{ borderRadius: "8px" }}
                    />
                    {uploadDone && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="tick-icon"
                        style={{ pointerEvents: "none" }}
                      />
                    )}

                    {!uploadDone && (
                      <>
                        {article && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCancelUpload}
                            style={{
                              cursor: "pointer",
                              color: clicked ? "red" : "blue",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default EditArticle;
