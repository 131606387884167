import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { FaEdit, FaListUl, FaHome } from "react-icons/fa";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import Footer from "./Footer";

const UsersDetails = () => {
  const [userData, setUserData] = useState([]);
  const [userImage, setUserImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/userDetails/${userId}`
        );
        // Assuming the response data is an object containing user details
        console.log(response.data);
        setUserImage(response.data);
        setUserData(response.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="teachers">
        <section className="content-header">
          <h3>User Detail</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">User Profile</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={`/userDetails/${userId}`}>
            <FaListUl className="icon" />
            View Info
          </Link>
          |
          <Link to="/editDetails">
            <FaEdit className="icon" />
            Edit Info
          </Link>
        </div>
        <br></br>
        <div className="teacherlist">
          <div className="col-md-3">
            <SideBar />
          </div>
          {loading ? (
            <p>
              <b>Loading...</b>
            </p>
          ) : (
            <>
              {userData.length === 0 ? (
                <div className="no-content-message">
                  <p>
                    No <i>content found for this page!</i>
                  </p>
                  <br></br>
                  <p>
                    Please click on the <b>+</b>Add button to add content...
                  </p>
                </div>
              ) : (
                userData.mediaType === "image" && (
                  <ol>
                    <li key={userData.id} className="custom-list-item">
                      {userImage && (
                        <img
                          src={URL.createObjectURL(userData.userImage)}
                          alt="User"
                        />
                      )}
                      <p>Username: {userData.username}</p>
                      <p>Email: {userData.email}</p>
                      <p>School: {userData.school}</p>
                      <p>Subject: {userData.subject}</p>
                    </li>
                  </ol>
                )
              )}
            </>
          )}
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default UsersDetails;
