import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FaPlus, FaListUl } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import { setToken } from "../Components/token";

const AddActivity = () => {
  const { sessionId } = useParams();
  const [activity_name, setActivity_Name] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [session, setSession] = useState([]);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSession/${sessionId}`
        );
        setSession(response.data);
        console.log("Session got", response.data);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, [sessionId]);

  function submitActivity(e) {
    e.preventDefault();
    axios
      .post("http://161.97.81.168:8080/addActivity/", {
        activity_name: activity_name,
        session: selectedSession,
      })
      .then(function (res) {
        console.log("Response", res);
        alert("Activity added successfully");
      });
  }

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <h3>Add Activity for Session...</h3>
        <div className="head">
          <a href="/topics/view">
            <FaListUl className="icon" />
            View Topics
          </a>{" "}
          |
          <a href="/topics/add/">
            <FaPlus className="icon" />
            Add Topics
          </a>
        </div>
        <br></br>
        <div className="activity-tab">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-container">
            <form className="form-submit" onSubmit={submitActivity}>
              <div>
                <label className="form-label" htmlFor="activity_name">
                  Activity Name:
                </label>
                <input
                  className="form-input"
                  type="text"
                  id="activity_name"
                  placeholder="Enter session name"
                  value={activity_name}
                  onChange={(e) => setActivity_Name(e.target.value)}
                  required
                />
              </div>
              <Form.Group className="mb-3" controlId="formBasicActivity">
                <Form.Label>Session Name:</Form.Label>
                <Form.Select
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Session
                  </option>
                  <option key={session.id} value={session.id}>
                    {session.sessionName}
                  </option>
                </Form.Select>
              </Form.Group>
              <hr></hr>
              <div className="submit-container">
                <button type="submit" className="edittopic">
                  Submit
                </button>
                <button className="back">
                  <Link to={`/sessions/view-session/${sessionId}`}>Back</Link>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddActivity;
