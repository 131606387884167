import React from "react";
import { useState, useEffect } from "react";
import { FaEdit, FaPlus, FaListUl, FaHome } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { getToken } from "../Components/token";
import SideBar from "../Components/SideBar";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const ViewSecTopics = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [topicId] = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      console.log("initiated:", token);
      try {
        const response = await axios.get(
          "http://161.97.81.168:8080/viewSecTopics/",
          {
            // headers: {
            //     Authorization: `Token ${token}`,
            // }
          }
        );
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleEditTopics = (topicId) => {
    navigate(`/updateSecTopic/${topicId}`);
    console.log("Add session for topic with code:", topicId);
  };

  const handleViewSession = (topicId) => {
    navigate(`/viewSecSessions/${topicId}`);
    console.log("Add session for topic with code:", topicId);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>List of Topics</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/ViewSecTopics">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Physics</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={"/viewSecTopics"}>
            <FaListUl className="icon" />
            View Topics
          </Link>
          |
          <Link to={"/addSecTopics/"}>
            <FaPlus className="icon" />
            Add Topics
          </Link>
        </div>
        <br></br>
        <div className="topicslist">
          <div className="col-md-3">
            <SideBar />
          </div>
          <table className="table-container">
            <thead>
              <tr>
                <th>Topic Code</th>
                <th>Topic Name</th>
                <th>Class</th>
                <th>Term</th>
                <th className="action">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.topicCode}</td>
                  <td>
                    <Link to={`/sessiondetailsSec/${item.id}`}>
                      {item.topicName}
                    </Link>
                  </td>
                  <td>{item.classTaught}</td>
                  <td>{item.term}</td>
                  <td className="action-column">
                    <button
                      className="edit"
                      onClick={() => handleEditTopics(item.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="session"
                      onClick={() => handleViewSession(item.id)}
                    >
                      Session
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* </div> */}
      <Footer version={version} />
    </>
  );
};

export default ViewSecTopics;

export const AddSecTopics = () => {
  const [topicCode, setTopicCode] = useState("");
  const [topicName, setTopicName] = useState("");
  const [classTaught, setClassTaught] = useState("");
  const [term, setTerm] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post("http://161.97.81.168:8080/addTopic/", {
          topicName: topicName,
          topicCode: topicCode,
          term: term,
          cat: "Secondary",
          subject: subject,
          classTaught: classTaught,
        })
        .then(function (res) {
          console.log("Response", res);
          navigate("/viewSecTopics");
        });
    } catch (error) {
      console.error("Error inserting data:", error);
      setMsg(error);
    }
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Add Topic</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/viewSecTopics">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Physics</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={"/viewSecTopics"}>
            <FaListUl className="icon" />
            View Topics
          </Link>
          |
          <Link to={"/addSecTopics/"}>
            <FaPlus className="icon" />
            Add Topics
          </Link>
        </div>
        <br></br>
        <div className="form-container">
          {/* <div className="topicslist"> */}
          <div className="col-md-3">
            <SideBar />
          </div>
          <form className="form-submit" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="topicCode">Topic Code:</label>
              <input
                type="text"
                id="topicCode"
                name="topicCode"
                value={topicCode}
                maxLength={5}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 5) {
                    setTopicCode(inputValue);
                  } else {
                    console.log("Topic code must be exactly 5 characters.");
                  }
                }}
                required
              />
            </div>
            <div>
              <label htmlFor="cat">Section</label>
              <input
                type="text"
                id="cat"
                name="cat"
                value="Secondary"
                required
              />
            </div>
            <div>
              <label htmlFor="name">Topic Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="classTaught">Class:</label>
              <select
                id="classTaught"
                name="classTaught"
                value={classTaught}
                onChange={(e) => setClassTaught(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select Class
                </option>
                <option value="S.1">S.1</option>
                <option value="S.2">S.2</option>
                <option value="S.3">S.3</option>
                <option value="S.4">S.4</option>
              </select>
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <select
                id="subject"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              >
                <option value="" disabled selected>
                  Select Subject
                </option>
                <option value="Biology">BIOLOGY</option>
                <option value="Chemistry">CHEMISTRY</option>
                <option value="Physics">PHYSICS</option>
              </select>
            </div>
            <div>
              <label htmlFor="term">Term:</label>
              <select
                id="term"
                name="term"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              >
                <option value="" disabled selected>
                  Choose Term
                </option>
                <option value="Term I">Term I</option>
                <option value="Term II">Term II</option>
                <option value="Term III">Term III</option>
              </select>
            </div>
            <div className="edittopics">
              <button type="submit" className="edittopic">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* </div> */}
      <Footer version={version} />
    </>
  );
};

export const EditSecTopics = () => {
  const [topicCode, setTopicCode] = useState("");
  const [topicName, setTopicName] = useState("");
  const [classTaught, setClassTaught] = useState("");
  const [term, setTerm] = useState("");
  const [subject, setSubject] = useState("");
  const [topic, setTopic] = useState([]);
  const { topicId } = useParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getTopic/${topicId}`
        );
        const topicData = response.data;
        setTopic(topicData);
        setTopicName(topicData.topicName);
        setTopicCode(topicData.topicCode);
        setTerm(topicData.term);
        setClassTaught(topicData.classTaught);
        setSubject(topicData.subject);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };
    fetchTopics();
  }, [topicId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios
        .put(`http://161.97.81.168:8080/updateTopic/${topicId}`, {
          topicName: topicName,
          topicCode: topicCode,
          term: term,
          cat: "Secondary",
          subject: subject,
          classTaught: classTaught,
        })
        .then((res) => {
          console.log("Response:", res);
          if (res.request.status === 200) {
            setMsg("Data Updated Successfully!");

            navigate("/viewSecTopics");
          } else {
            setMsg("if, Error");
          }
        });
    } catch (error) {
      console.error("Error updating data:", error);
      setMsg("Error");
    }
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>Edit Topic... {topic.topicName}</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/ViewSecTopics">
                  <FaHome className="breadcrumb-icon" />
                </Link>
              </li>
              <li className="active">updateTopic</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={"/viewSecTopics"}>
            <FaListUl className="icon" />
            View Topics
          </Link>
          |
          <Link to={"/addSecTopics/"}>
            <FaPlus className="icon" />
            Add Topics
          </Link>
        </div>
        <br></br>
        <div className="form-container">
          <div className="col-md-3">
            <SideBar />
          </div>
          <form className="form-submit" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="topicCode">Topic Code:</label>
              <input
                type="text"
                id="topicCode"
                name="topicCode"
                value={topicCode}
                maxLength={5}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 5) {
                    setTopicCode(inputValue);
                  } else {
                    console.log("Topic code must be exactly 5 characters.");
                  }
                }}
                required
              />
            </div>
            <div>
              <label htmlFor="cat">Section</label>
              <input
                type="text"
                id="cat"
                name="cat"
                value="Secondary"
                required
              />
            </div>
            <div>
              <label htmlFor="name">Topic Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="classTaught">Class:</label>
              <select
                id="classTaught"
                name="classTaught"
                value={classTaught}
                onChange={(e) => setClassTaught(e.target.value)}
                required
              >
                <option value="Select Class" disabled>
                  {topic.classTaught}
                </option>
                <option value="S.1">S.1</option>
                <option value="S.2">S.2</option>
                <option value="S.3">S.3</option>
                <option value="S.4">S.4</option>
              </select>
            </div>
            <div>
              <label htmlFor="subject">Subject:</label>
              <select
                id="subject"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              >
                <option value="Select Subject" disabled>
                  {topic.subject}
                </option>
                <option value="Biology">BIOLOGY</option>
                <option value="Chemistry">CHEMISTRY</option>
                <option value="Physics">PHYSICS</option>
              </select>
            </div>
            <div>
              <label htmlFor="term">Term:</label>
              <select
                id="term"
                name="term"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              >
                <option value="Choose Term" disabled>
                  {topic.term}
                </option>
                <option value="Term I">Term I</option>
                <option value="Term II">Term II</option>
                <option value="Term III">Term III</option>
              </select>
            </div>
            <div className="edittopics">
              <button type="submit" className="edittopic">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};
