import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import { FaPlus, FaListUl, FaHome } from "react-icons/fa";
import axios from "axios";
import { getToken } from "../Components/token";
import { Link, useNavigate } from "react-router-dom";

const Theme = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.get(
          "http://161.97.81.168:8080/viewTheme/"
        );
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchThemes();
  }, []);

  const handleUpdateActivity = (id) => {
    navigate(`/editTheme/${id}`);
    console.log("Update for theme with id: ", id);
  };

  const handleView = (theme_id) => {
    navigate(`/viewSubTheme/${theme_id}`);
  };

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>List of Themes...</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Themes</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="head">
          <Link to={"/viewThemes"}>
            <FaListUl className="icon" />
            View Themes
          </Link>
          |
          <Link to={"/addTheme"}>
            <FaPlus className="icon" />
            Add Theme
          </Link>
        </div>
        <br></br>
        <div className="topicslist">
          <div className="col-md-3">
            <SideBar />
          </div>
          <table className="table-container">
            <thead>
              <tr>
                <th>Theme Code</th>
                <th>Theme Name</th>
                <th>Class</th>
                <th>Term</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.themeCode}</td>
                  <td>
                    <Link to={`/subThemeDetails/${item.id}`}>{item.title}</Link>
                  </td>
                  <td>{item.classTaught}</td>
                  <td>{item.term}</td>
                  <td className="action-columns">
                    <button
                      className="edit"
                      onClick={() => handleUpdateActivity(item.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="subtheme"
                      onClick={() => handleView(item.id)}
                    >
                      Sub-Themes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default Theme;
