import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import axios from "axios";
import { getToken } from "../Components/token";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const ThemesList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchThemes = async () => {
      const token = getToken();
      console.log("initiated:", token);
      try {
        const response = await axios.get(
          "http://161.97.81.168:8080/viewTheme/"
        );
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchThemes();
  }, []);

  const version = "1.0.0";

  return (
    <>
      <Navbar />
      <div className="topics">
        <section className="content-header">
          <h3>List of Themes...</h3>
          <nav className="breadcrumb">
            <ol>
              <li>
                <Link to="/dashboard">
                  <FaHome className="breadcrumb-icon" />
                  Home
                </Link>
              </li>
              <li className="active">Themes</li>
            </ol>
          </nav>
        </section>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>

        <br></br>
        <div className="topicslist">
          <div className="col-md-3">
            <SideBar />
          </div>
          <table className="table-container">
            <thead>
              <tr>
                <th>Theme Code</th>
                <th>Theme Name</th>
                <th>Class</th>
                <th>Term</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.themeCode}</td>
                  <td>{item.title}</td>
                  <td>{item.classTaught}</td>
                  <td>{item.term}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default ThemesList;
