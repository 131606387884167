import React, { useRef, useState, useEffect } from "react";
import axios, { CancelToken } from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SideBar from "../Components/SideBar";
import Footer from "../Components/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setToken } from "../Components/token";
import BooleanCheckbox from "./BooleanCheckbox"; // import the BooleanCheckbox component

const AddActivityDetails = () => {
  const { sessionId } = useParams();
  const [percentage, setPercentage] = useState(0);
  const cancelUpload = useRef(null);
  const [session, setSession] = useState({});
  const [activityTitle, setActivityTitle] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [teachersActivity, setTeachersActivity] = useState("");
  const [studentsActivity, setStudentsActivity] = useState("");
  const [notes, setNotes] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [uploadDone, setUploadDone] = useState(false);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  const [mediaType, setMediaType] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [realvideo, setRealvideo] = useState(""); 

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
      ["link", "image", "video"],
    ],
  };

  // Fetch session details
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `http://161.97.81.168:8080/getSession/${sessionId}`
        );
        setSession(response.data);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };
    fetchSession();
  }, [sessionId]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let formData = new FormData();
      formData.append("mediaType", mediaType);
      formData.append("title", activityTitle);
      formData.append("session", session.id);
      formData.append("time", selectedTime);

      if (mediaType === "text") {
        formData.append("teacherActivity", teachersActivity);
        formData.append("studentActivity", studentsActivity);
        formData.append("notes", notes);
      } else if (mediaType === "image") {
        formData.append("image", image);
        formData.append("image_title", imageTitle);
      } else if (mediaType === "video") {
        formData.append("video", video);
        formData.append("real_video", realvideo); 
        formData.append("video_title", videoTitle);
        formData.append("time", selectedTime);
        formData.append("mediaType", "video");
      }

      // Make POST request to upload media
      const response = await axios.post(
        "http://161.97.81.168:8080/addActivity/",
        formData,
        {
          onUploadProgress: (data) => {
            setPercentage(Math.round((100 * data.loaded) / data.total));
          },
          cancelToken: new CancelToken(
            (cancel) => (cancelUpload.current = cancel)
          ),
        }
      );
      setUploadDone(true);
      console.log("Media uploaded successfully:", response.data);
      setToken(response.data.token);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const handleCancelUpload = () => {
    if (cancelUpload.current) cancelUpload.current("");
    setClicked(true);
    setPercentage(0);
  };

  const handleBack = () => {
    navigate(`/activity-list/${sessionId}`);
  };

  const version = "1.0.0";
  

  return (
    <>
      <Navbar />
      <div className="sessions">
        <h3>Add Activity Details: [{session.sessionName}]</h3>
        <hr style={{ width: "100%", color: "#337ab7" }}></hr>
        <div className="adddetails-tab">
          <div className="col-md-3">
            <SideBar />
          </div>
          <div className="form-addactivitydetails">
            <div className="activity-row">
              <h2>Learning Activities:</h2>
              <div className="center">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="mediaType"
                        value="text"
                        onChange={() => setMediaType("text")}
                      />
                      Text
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="mediaType"
                        value="image"
                        onChange={() => setMediaType("image")}
                      />
                      Image
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="mediaType"
                        value="video"
                        onChange={() => setMediaType("video")}
                      />
                      Video
                    </label>
                  </div>

                  {mediaType === "text" && (
                    <div>
                      <div>
                        <label className="form-label" htmlFor="activityTitle">
                          Activity Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="activityTitle"
                          value={activityTitle}
                          onChange={(e) => setActivityTitle(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="session">
                          Session:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="session"
                          value={session.sessionName}
                          readOnly
                          required
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="selectedTimeText">
                          Time Allocation:
                        </label>
                        <select
                          id="selectedTimeText"
                          name="selectedTimeText"
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select Time
                          </option>
                          <option value="05">05 mins</option>
                          <option value="10">10 mins</option>
                          <option value="15">15 mins</option>
                          <option value="20">20 mins</option>
                          <option value="25">25 mins</option>
                        </select>
                      </div>

                      <div>
                        <label className="form-label" htmlFor="teacherActivity">
                          Teachers Activities:
                        </label>
                        <div className="wrapper">
                          <ReactQuill
                            className="ql-editor"
                            style={{
                              height: "200px",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            theme="snow"
                            placeholder="Teachers' Activities"
                            value={teachersActivity}
                            onChange={(value) => setTeachersActivity(value)}
                            modules={modules}
                            required
                          />
                        </div>
                      </div>

                      <div>
                        <label className="form-label" htmlFor="studentActivity">
                          Learners Activities:
                        </label>
                        <div className="wrapper">
                          <ReactQuill
                            className="ql-editor custom-editor"
                            style={{
                              height: "200px",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            theme="snow"
                            placeholder="Learners' Activities"
                            value={studentsActivity}
                            onChange={(value) => setStudentsActivity(value)}
                            modules={modules}
                            required
                          />
                        </div>
                      </div>

                      <div>
                        <label className="form-label" htmlFor="notes">
                          Notes/Links/References:
                        </label>
                        <div className="wrapper">
                          <ReactQuill
                            className="ql-editor custom-editor"
                            style={{
                              height: "200px",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            theme="snow"
                            placeholder="Notes/Links/References"
                            value={notes}
                            onChange={(value) => setNotes(value)}
                            modules={modules}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {mediaType === "image" && (
                    <div>
                      <div>
                        <label className="form-label" htmlFor="image">
                          Upload Image:
                        </label>
                        <input
                          type="file"
                          id="image"
                          accept="image/*"
                          onChange={(e) => setImage(e.target.files[0])}
                          required
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="imageTitle">
                          Image Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="imageTitle"
                          value={imageTitle}
                          onChange={(e) => setImageTitle(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="session">
                          Session:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="session"
                          value={session.sessionName}
                          readOnly
                          required
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="selectedTime">
                          Time Allocation:
                        </label>
                        <select
                          id="selectedTime"
                          name="selectedTime"
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select Time
                          </option>
                          <option value="05">05 mins</option>
                          <option value="10">10 mins</option>
                          <option value="15">15 mins</option>
                          <option value="20">20 mins</option>
                          <option value="25">25 mins</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {mediaType === "video" && (
                    <div>
                      <div>
                        <label className="form-label" htmlFor="activityTitle">
                          Activity Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="activityTitle"
                          value={activityTitle}
                          onChange={(e) => setActivityTitle(e.target.value)}
                          required
                        />
                      </div>

                      
                      <div>
                        <label className="form-label" htmlFor="session">
                          Session:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="session"
                          value={session.sessionName}
                          readOnly
                          required
                        />
                      </div>
                      
                      <div>
                        <label className="form-label" htmlFor="selectedTime">
                          Time Allocation:
                        </label>
                        <select
                          id="selectedTime"
                          name="selectedTime"
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select Time
                          </option>
                          <option value="05">05 mins</option>
                          <option value="10">10 mins</option>
                          <option value="15">15 mins</option>
                          <option value="20">20 mins</option>
                          <option value="25">25 mins</option>
                        </select>
                      </div>

                      <div>
                        <label className="form-label" htmlFor="videoTitle">
                          Video Title:
                        </label>
                        <input
                          className="form-input"
                          type="text"
                          id="videoTitle"
                          value={videoTitle}
                          onChange={(e) => setVideoTitle(e.target.value)}
                          required
                        />
                      </div>

                      <div>
                      <label className="form-label" htmlFor="videofile">
                          Video:
                        </label>
                      <div className="videofile">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => setVideo(e.target.files[0])}
                      />
                       <select
                          id="selectedvideo"
                          name="selectedvideo"
                          value={realvideo}
                          onChange={(e) => setRealvideo(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select VideoType
                          </option>
                          <option value="realvideo">Actual Video</option>
                          <option value="placeholder">Placeholder</option>
                        </select>
                      </div>
                      </div>
                    </div>
                  )}

                <div className="submit-container">
                    <button type="submit" className="edittopic">
                      Upload
                    </button>
                    <button type="button" className="back" onClick={handleBack}>
                      Back
                    </button>
                  </div>
                  <div className="progress-bar">
                    <span>{`${percentage}%`}</span>
                    <progress
                      className="progress"
                      value={percentage}
                      max="100"
                      style={{ borderRadius: "8px" }}
                    />
                    {uploadDone && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="tick-icon"
                        style={{ pointerEvents: "none" }}
                      />
                    )}

                    {!uploadDone && (
                      <>
                        {image && mediaType === "image" && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCancelUpload}
                            style={{
                              cursor: "pointer",
                              color: clicked ? "red" : "blue",
                            }}
                          />
                        )}
                        {video && mediaType === "video" && (
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCancelUpload}
                            style={{
                              cursor: "pointer",
                              color: clicked ? "red" : "blue",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer version={version} />
    </>
  );
};

export default AddActivityDetails;